import React, { useEffect } from "react";
import { useSetState } from "ahooks";
import { useHistory, useParams } from "react-router-dom";
import { Loader } from "components/reusable/Loader";
import useAuthenticatedUser from "../../hooks/useAuthenticatedUser";
import errorWarning from "../../resources/img/icons/error-warning.svg";
import { fetchEnrolledUser, fetchUsersJobs } from "../../util/api_util";
import { formatDashboardJobs } from "../../util/selectors";
import JobResultCard from "./kyc_card";

const KYC: React.FC = () => {
  const [state, setState] = useSetState({
    result: null,
    resultCodes: null,
    error: null,
    message: null,
    user: null,
    selfie: null,
    actions: null,
    id_checks: null,
    e_signatures: null,
    original_id_cards: null,
    jobs: [],
    jobListJobs: [] as object[],
    smile_secure_enabled: false,
    isLoading: false,
    jobsError: null,
  });
  const history = useHistory();
  const { userType } = useAuthenticatedUser();
  const { userId, partnerId, environment } = useParams<{
    userId: string;
    partnerId: string;
    environment: string;
  }>();

  useEffect(() => {
    const baseUrl =
      userType === "admin"
        ? `admin/${partnerId}/kyc/${environment}/${userId}`
        : `/partner/kyc/${environment}/${userId}`;

    sessionStorage.setItem("url", baseUrl);

    const isProduction = environment === "production";
    sessionStorage.setItem("displayEnvironment", isProduction.toString());

    if (userType) getData();
  }, [userId, partnerId, environment, userType]);

  const getData = () => {
    setState({ isLoading: true });
    const sortingParams: Record<string, string> = {};
    if (userType === "admin") {
      sortingParams.partner_id = partnerId;
    }

    type Response =
      | { [key: string]: string | null | undefined | object }
      | undefined;
    fetchEnrolledUser(userId, sortingParams)
      .then((res) => {
        const resp = res as Response;
        setState({
          error: resp?.error,
          user: resp?.enrollee,
          jobs: resp?.jobs,
          actions: resp?.actions,
          selfie: resp?.enrolled_image,
          e_signatures: resp?.e_signatures,
          id_checks: (resp?.id_checks as object[])?.concat(
            resp?.documents as object[],
          ),
          original_id_cards: resp?.original_id_cards,
          smile_secure_enabled: resp?.smile_secure_enabled,
        } as object);
      })
      .finally(() => setState({ isLoading: false }));
    sortingParams.partner_uid = userId;

    fetchUsersJobs(sortingParams).then((res) => {
      const resp = res as Response;
      setState({
        jobsError: resp?.error,
        jobListJobs: formatDashboardJobs(resp?.jobs, resp?.result_codes),
      } as object);
    });
  };

  return (
    <>
      <div className="breadcrumbs">
        <a
          className="breadcrumb-link breadcrumb-link--inactive"
          href={
            userType === "admin"
              ? `/admin/partner_dashboards/${partnerId}/user_list`
              : "/partner/user_list"
          }
        >
          User List
        </a>
        <span> &gt; </span>
        <span>KYC</span>
      </div>

      {state.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader size="xl" />
        </div>
      )}

      {state.error && (
        <div className="no-info__container">
          <img className="icon--large" src={errorWarning} alt="Error" />
          <div className="no-info">{state.error}</div>
        </div>
      )}

      {state.jobsError && (
        <div className="no-info__container">
          <img className="icon--large" src={errorWarning} alt="Error" />
          <div className="no-info">{state.jobsError}</div>
        </div>
      )}

      {state.user && (
        <JobResultCard
          jobs={state.jobs}
          selfie={state.selfie}
          user={state.user}
          actions={state.actions}
          id_checks={state.id_checks}
          e_signatures={state.e_signatures}
          original_id_cards={state.original_id_cards}
          jobListJobs={state.jobListJobs}
          history={history}
          partnerId={partnerId}
          smile_secure_enabled={state.smile_secure_enabled}
          userType={userType}
        />
      )}
    </>
  );
};

export default KYC;
