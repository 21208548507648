import React from "react";
import classNames from "classnames";
import userIDauthImageIcon from "resources/img/icons/governmentIcon.svg";
import livenessIcon from "resources/img/icons/livenessIcon.svg";
import userSelfieIcon from "resources/img/icons/userSelfieIcon.svg";
import "./ImageDescriptions.scss";

export const ImageDescriptionMap = {
  id_authority: {
    icon: userIDauthImageIcon,
    description: "Image from ID authority",
  },
  id_authority_document: {
    icon: userIDauthImageIcon,
    description: "Image from ID authority",
  },
  id_card: {
    icon: userIDauthImageIcon,
    description: "Front of ID document",
  },
  id_card_back: {
    icon: userIDauthImageIcon,
    description: "Back of ID document",
  },
  liveness: {
    icon: livenessIcon,
    description: "Liveness image",
  },
  selfie: {
    icon: userSelfieIcon,
    description: "User submitted selfie",
  },
  unknown: {
    icon: userSelfieIcon,
    description: "Unknown document type",
  },
};

const ImageDescription = ({ descriptionType, source, className = "" }) => {
  const { icon, description } =
    ImageDescriptionMap[descriptionType] || ImageDescriptionMap.unknown;

  return (
    <div className={classNames(className, "image_description_container")}>
      <div className="image_description">
        <div className="image_description_icon">
          <img src={icon} alt={description} width="16" height="16" />
        </div>
        <span className="image_description_text p2">{description}</span>
      </div>
      {!!source && (
        <span className={`image_description_sub_text ${source}`}>{source}</span>
      )}
    </div>
  );
};

export { ImageDescription };
